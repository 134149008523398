import Environment, {ResolveEnvironment} from '@/config/Environment';
import type {ResolveEnvironmentParams} from '@/config/Environment';

const environment = ResolveEnvironment({
  Local: 'local',
  CI: 'ci',
  Dev: 'development',
  Prod: 'production',
});

export const isSentryEnabled = ResolveEnvironment({
  Local: false,
  CI: false,
  Dev: true,
  Prod: true,
  // force these types in case we want to override this locally temporarily such that compilation will catch this
} as Omit<ResolveEnvironmentParams<boolean>, 'Local'> & {Local: false});

export default class ErrorLogger {
  static _Sentry: any = null;

  static Initialize(Sentry, {integrationsToAdd, ...sentryConfig}: any = {}) {
    ErrorLogger._Sentry = Sentry;
    if (isSentryEnabled) {
      if (Environment.IsServer || Environment.IsLocal) {
        console.log(`initializing Sentry with env "${environment}" ...`);
      }
      Sentry.init({
        tracesSampleRate: 1,
        ...sentryConfig,
        environment,
        integrations(integrations) {
          // TODO bun-node-issue
          // filter out http integration because it is broken in bun right now
          // @see https://github.com/getsentry/sentry-javascript/issues/12891
          integrations = integrations.filter((integration) => integration.name !== 'Http');
          try {
            const onUncaughtExceptionIntegration = integrations.find((integration) => {
              return integration.name === 'OnUncaughtException';
            });

            if (onUncaughtExceptionIntegration) {
              // this is only defined in node env.
              // we do not want sentry to exist here on uncaught exceptions
              onUncaughtExceptionIntegration._options = {
                ...onUncaughtExceptionIntegration._options,
                exitEvenIfOtherHandlersAreRegistered: false,
              };
            }

            if (integrationsToAdd?.length) {
              integrations.push(...integrationsToAdd);
            }

            return integrations;
          } catch (error) {
            // if any of this errors, errors won't go to sentry
            console.error(error);
          }
        },
      });
    } else {
      if (Environment.IsServer || Environment.IsCI) {
        console.log(`Sentry is currently disabled in env "${environment}"`);
      }
    }
  }

  static Log(
    error: Error,
    captureException: typeof ErrorLogger._Sentry.captureException = ErrorLogger._Sentry!
      .captureException,
  ) {
    if (error._ignoreLog) {
      return 'ignored-error-id';
    }
    if (error._sentryErrorId) {
      return error._sentryErrorId;
    }
    let sentryErrorId: string;
    if (isSentryEnabled && Environment.IsServer) {
      sentryErrorId = captureException(error);
      console.error('Sentry error captured with ID:', sentryErrorId);
    } else {
      console.error(error);
      sentryErrorId = '00000000000000000000000000000000';
    }
    error._sentryErrorId = sentryErrorId;
    return sentryErrorId;
  }

  static async Flush() {
    const Sentry = ErrorLogger._Sentry;
    if (Sentry) {
      return Sentry.flush();
    }
  }
}
